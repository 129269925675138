(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/ui/bottom-sheet/assets/javascripts/bottom-sheet.js') >= 0) return;  svs.modules.push('/components/ui/bottom-sheet/assets/javascripts/bottom-sheet.js');

'use strict';

const DIRECTION_DOWN = 'down';
const DIRECTION_UP = 'up';
svs.ui = svs.ui || {};
svs.ui.BottomSheet = class BottomSheet {
  constructor(options) {
    this.settings = {
      appendTo: (options === null || options === void 0 ? void 0 : options.appendTo) || document.body,
      classNames: (options === null || options === void 0 ? void 0 : options.classNames) || '',
      customer: (options === null || options === void 0 ? void 0 : options.customer) || 'neutral',
      behavior: (options === null || options === void 0 ? void 0 : options.behavior) || 'standard',
      isExpanded: (options === null || options === void 0 ? void 0 : options.isExpanded) || false,
      isFixed: (options === null || options === void 0 ? void 0 : options.isFixed) || false,
      isModal: options.behavior === 'modal',
      inverted: (options === null || options === void 0 ? void 0 : options.inverted) || false,
      transparent: (options === null || options === void 0 ? void 0 : options.transparent) || false,
      id: new Date().getTime() + Math.floor(Math.random() * 9999),
      log: window.svs ? new window.svs.core.log.Logger('BottomSheet') : console,
      content: options === null || options === void 0 ? void 0 : options.content,
      headerTitle: options === null || options === void 0 ? void 0 : options.headerTitle,
      allowHeaderSwipe: options === null || options === void 0 ? void 0 : options.allowHeaderSwipe,
      footerContent: options === null || options === void 0 ? void 0 : options.footerContent,
      twoStepExpansion: options.twoStepExpansion,
      disablePageContentSpacing: options.disablePageContentSpacing,
      collapsedHeight: options.collapsedHeight ? options.collapsedHeight : '0px',
      onStateChange: options === null || options === void 0 ? void 0 : options.onStateChange,
      hideSheet: this.hideSheet.bind(this)
    };
    this.utils = new svs.ui.BottomSheet.BottomSheetUtils({
      settings: this.settings
    });
    this.listeners = new svs.ui.BottomSheet.BottomSheetListeners({
      utils: this.utils
    });

    this.render();
  }

  setContent() {
    const settings = this.settings;
    const sheetHtml = svs.ui.bottom_sheet.partials.sheet({
      classNames: settings.classNames += " js-bottom-sheet-".concat(settings.id).concat(settings.isFixed ? ' bottom-sheet-fixed' : '', " bottom-sheet-").concat(settings.customer ? settings.customer : 'neutral'),
      customer: "bottom-sheet-".concat(settings.customer ? settings.customer : 'neutral'),
      inverted: settings.inverted,
      transparent: settings.transparent,
      content: settings.content,
      headerTitle: settings.headerTitle,
      isFixed: settings.isFixed,
      isModal: settings.isModal,
      footerContent: settings.footerContent
    });
    return sheetHtml;
  }

  initialize() {
    this.settings.el = this.settings.el ? this.settings.el : document.querySelector(".js-bottom-sheet-".concat(this.settings.id));

    if (this.settings.isExpanded) {
      this.settings.gestureDirection = DIRECTION_UP;
    }
    this.utils.updateSettings(this.settings);
    this.listeners.init();
    this.utils.setState();

    let timeout;
    const setMeasurements = () => {
      this.utils.setMeasurements();
    };
    window.onresize = () => {
      clearTimeout(timeout);
      timeout = setTimeout(setMeasurements, 100);
    };
  }
  render() {
    const content = this.setContent();
    const drawerOptions = {
      position: 'bottom',
      autoFold: false,
      content,
      class: this.settings.classNames,
      disableDimmerClick: true,
      isDimmerDisabled: this.settings.behavior === 'standard',
      isSheet: true
    };
    this.settings.bottomSheet = new svs.ui.Drawer(drawerOptions);
    this.utils.updateSettings(this.settings);
    this.utils.setMeasurements(this.settings.isExpanded);
  }

  show() {
    this.initialize();
    this.settings.bottomSheet.open();
    if (this.settings.behavior !== 'modal') {
      this.settings.bottomSheet.hideDimmer();
    }
  }
  hideSheet() {
    const settings = this.utils.getSettings();
    settings.gesuterDirection = DIRECTION_DOWN;
    this.utils.updateSettings(settings);
    this.utils.setState();
    this.settings.bottomSheet.close();
    this.settings.bottomSheet.hideDimmer();
  }
  destroy() {
    var _settings$touchContro, _settings$swipeContro;
    const settings = this.utils.getSettings();
    (_settings$touchContro = settings.touchController) === null || _settings$touchContro === void 0 || _settings$touchContro.abort();
    (_settings$swipeContro = settings.swipeController) === null || _settings$swipeContro === void 0 || _settings$swipeContro.abort();
    settings.bottomSheet.remove();
  }
};

 })(window);