(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/ui/bottom-sheet/views/sheet_partial.js') >= 0) return;  svs.modules.push('/components/ui/bottom-sheet/views/sheet_partial.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.ui=_svs.ui||{};
_svs.ui.bottom_sheet=_svs.ui.bottom_sheet||{};
_svs.ui.bottom_sheet.partials=_svs.ui.bottom_sheet.partials||{};
svs.ui.bottom_sheet.partials.sheet = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " bottom-sheet-transparent";
},"3":function(container,depth0,helpers,partials,data) {
    return " bottom-sheet-inverted";
},"5":function(container,depth0,helpers,partials,data) {
    return " bottom-sheet-default";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isModal") : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":10},"end":{"line":12,"column":21}}})) != null ? stack1 : "")
    + "          <div class=\"js-bottom-sheet-draggable bottom-sheet-draggable\">\n"
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(alias1,"icon-button",{"name":"ui","hash":{"ariaLabel":"Dra för att maximera eller minimera"},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":16,"column":19}}})) != null ? stack1 : "")
    + "          </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"js-bottom-sheet-collapse bottom-sheet-collapse bottom-sheet-close-hide\">\n"
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"icon-button",{"name":"ui","hash":{"ariaLabel":"Minimera","class":"bottom-sheet-action-icon"},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":14},"end":{"line":10,"column":21}}})) != null ? stack1 : "")
    + "            </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"icon",{"name":"ui","hash":{"size":"200","icon":"menu-down","class":"bottom-sheet-control-icon"},"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":91}}}))
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "              <span class='bottom-sheet-drag-handle'></span>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"js-bottom-sheet-close bottom-sheet-close bottom-sheet-close-hide\">\n"
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"icon-button",{"name":"ui","hash":{"ariaLabel":"Stäng","class":"bottom-sheet-action-icon"},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":23,"column":19}}})) != null ? stack1 : "")
    + "          </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"icon",{"name":"ui","hash":{"size":"200","icon":"close","class":"bottom-sheet-control-icon"},"data":data,"loc":{"start":{"line":22,"column":14},"end":{"line":22,"column":85}}}))
    + "\n";
},"16":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <p class=\"bottom-sheet-title\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"headerTitle") || (depth0 != null ? lookupProperty(depth0,"headerTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"headerTitle","hash":{},"data":data,"loc":{"start":{"line":27,"column":40},"end":{"line":27,"column":55}}}) : helper)))
    + "</p>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"bottom-sheet-footer\">\n          "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"footerContent") || (depth0 != null ? lookupProperty(depth0,"footerContent") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"footerContent","hash":{},"data":data,"loc":{"start":{"line":34,"column":10},"end":{"line":34,"column":29}}}) : helper))) != null ? stack1 : "")
    + "\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"js-bottom-sheet bottom-sheet"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"transparent") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":40},"end":{"line":1,"column":91}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"inverted") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":1,"column":91},"end":{"line":1,"column":165}}})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"classNames") || (depth0 != null ? lookupProperty(depth0,"classNames") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"classNames","hash":{},"data":data,"loc":{"start":{"line":1,"column":166},"end":{"line":1,"column":180}}}) : helper)))
    + "\">\n  <div class=\"bottom-sheet-content js-bottom-sheet-content\">\n    <div class=\"bottom-sheet-watcher-start js-bottom-sheet-watch-start\"></div>\n      <div class=\"bottom-sheet-header-control js-bottom-sheet-header-control "
    + alias4(((helper = (helper = lookupProperty(helpers,"customer") || (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"customer","hash":{},"data":data,"loc":{"start":{"line":4,"column":77},"end":{"line":4,"column":89}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFixed") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":18,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isModal") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":25,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"headerTitle") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":28,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n    <div class=\"js-bottom-sheet-children bottom-sheet-children\">\n      "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"content") || (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data,"loc":{"start":{"line":31,"column":6},"end":{"line":31,"column":19}}}) : helper))) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"footerContent") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":6},"end":{"line":36,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"bottom-sheet-watcher js-bottom-sheet-watch-end\"></div>\n  </div>\n</div>\n";
},"useData":true});
Handlebars.partials['ui-bottom-sheet-sheet'] = svs.ui.bottom_sheet.partials.sheet;
})(svs, Handlebars);


 })(window);